Drupal.behaviors.gnavCart = (function ($) {
  // Nav count
  var itemsCountTarget = '.gnav-util__icon__cart-count';
  // Cart count
  var itemsCountOrigin = '.js-cart-sidebar-counter-icon';
  // Update target with origin
  var itemsCountUpdateOn = [
    '.js-cart-sidebar-close-icon',
    '.js-cart-item-remove',
    '.js-overlay-shadow-visible',
    '.js-spinner-selector-button-back',
    '.js-spinner-selector-button-forward'
  ];
  var itemsCount = 0;
  var $itemCount = $();
  var $document = $(document);
  var site;
  var ElcServiceBus;
  var ServiceBusTopics;
  var behavior = {};

  function getCountFromResult(result) {
    return parseInt(result.trans_data.items_count, 10);
  }

  function updateItemsCount(count) {
    $itemCount.text((count === 0 ? '' : count));
  }

  function addCartSuccess(cartResult) {
    var itemsCount = getCountFromResult(cartResult);
    ElcServiceBus.emit(ServiceBusTopics.events.CART_UPDATED, {
      newDisplayState: true,
      newVisibility: true
    });
    updateItemsCount(itemsCount);
  }

  behavior = {
    attached: false,
    attach: function () {
      if (this.attached) {
        return;
      }
      this.attached = true;
      site = window.site || {};
      ElcServiceBus = window.ElcServiceBus || {};
      ServiceBusTopics = window.ServiceBusTopics || {};
      $itemCount = $(itemsCountTarget);
      itemsCount = parseInt(site.userInfoCookie.getValue('item_count'), 10) || 0;
      updateItemsCount(itemsCount);
    }
  };

  $document.on('addToCart.success', function (e, cartResult) {
    e.preventDefault();
    addCartSuccess(cartResult);

    return false;
  });

  $document.on('addKitToCart.success', function (e, cartResult) {
    e.preventDefault();
    addCartSuccess(cartResult);

    return false;
  });

  // Need a service bus event to trigger remove and qty updates
  $.each(itemsCountUpdateOn, function (i) {
    $document.on('click', itemsCountUpdateOn[i], function (e) {
      e.preventDefault();
      setTimeout(function () {
        itemsCount = parseInt($(itemsCountOrigin).text(), 10);
        updateItemsCount(itemsCount);
      }, 2000);

      return false;
    });
  });

  $document.on('click', '.js-gnav-util-trigger--cart-sidebar', function (e) {
    e.preventDefault();
    ElcServiceBus.emit(ServiceBusTopics.events.CART_OVERLAY_ICON_CLICKED);

    return false;
  });

  return behavior;
})((window.jQuery = window.jQuery || function () {}));
